import { css } from '@emotion/css';

const lg = css`
  display: flex;
  justify-content: center;
  margin: 40px auto;

  & > div {
    width: 16px;
    height: 16px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-16px);
    }
  }
`;

const sm = css`
  display: inline-flex;
  justify-content: start;
  margin: 2px auto;
  /* border: solid 1px red; */

  & > div {
    width: 6px;
    height: 6px;
    margin: 2px 1px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  & > div:nth-child(2) {
    animation-delay: 0.2s;
  }

  & > div:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-6px);
    }
  }
`;

const btn = css`
  ${sm};
  padding-top: 6px;
  & > div {
    background-color: #fff;
  }
`;

export default function BouncingLoader({ variant = 'lg' }: { variant?: 'sm' | 'lg' | 'btn' }) {
  let className = lg;
  if (variant === 'sm') {
    className = sm;
  }
  if (variant === 'btn') {
    className = btn;
  }
  return (
    <div className={className}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}
