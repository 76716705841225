export interface RuntimeEnv {
  version: string;
  stage: string;
  sha: string;
  auth0Domain: string;
  auth0ClientId: string;
  auth0Audience: string;
  apiBaseUrl: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const runtimeEnv = (window as any).__RUNTIME_ENV__ as RuntimeEnv;

export const baseUrl = import.meta.env.BASE_URL;

export function getFullPath(path: string) {
  return `${baseUrl}${path.startsWith('/') ? path.slice(1) : path}`;
}
