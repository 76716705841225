/** @jsxImportSource react */
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useMemo } from 'react';
import { Button, Flex, Image, Stack, Text } from '@chakra-ui/react';

const icons = import.meta.glob('../assets/auth0/*.svg', { eager: true });

export const CustomSSOLoginPage = ({
  appName,
  connection,
}: {
  appName: string;
  connection: string;
}) => {
  const { loginWithPopup } = useAuth0();

  const onSSOChatLogin = useCallback(async () => {
    await loginWithPopup({
      authorizationParams: { connection },
    });
  }, []);

  const iconBubblesUrl = useMemo(() => {
    const iconKey = Object.keys(icons).find((key) => key.endsWith('bubbles.svg'));
    return iconKey && (icons[iconKey] as { default: string } | undefined)?.default;
  }, []);

  return (
    <Stack
      w="380px"
      h="630px"
      py="140px"
      gap="14px"
      alignItems="center"
      bg="white"
      m="auto"
      overflow="hidden"
    >
      <Image src={iconBubblesUrl} boxSize="110px" />
      <Text
        fontSize="18px"
        color="#000"
        lineHeight="22px"
        textAlign="center"
        mt="40px"
        fontWeight={600}
      >
        Hi 👋, welcome to
        <br />
        {appName || 'Smart Assistance'}
      </Text>

      <Flex w="full" h="full" pos="relative">
        <Stack w="full" h="full" alignItems="center">
          <Text
            w="240px"
            my="10px"
            textAlign="center"
            color="#000"
            fontSize="14px"
            fontWeight={400}
            lineHeight="20px"
          >
            Start a chat with your AI assistant for expert knowledge and seamless support, anytime
            you need it!
          </Text>
          <Button mt="14px" gap="8px" h="35px" w="110px" onClick={onSSOChatLogin} variant="primary">
            Start a chat
          </Button>
        </Stack>
      </Flex>
    </Stack>
  );
};
