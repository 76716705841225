import { useRef, useEffect, RefObject } from 'react';

export default function ScrollPoint({
  active,
  scrollContainerRef,
  onBottomScrolled,
}: {
  active: boolean;
  scrollContainerRef: RefObject<HTMLDivElement>;
  onBottomScrolled: () => void;
}) {
  const scrollPointRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!active) {
      return;
    }
    scrollPointRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  });

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    const scrollPoint = scrollPointRef.current;

    if (!scrollContainer || !scrollPoint) {
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onBottomScrolled();
        }
      },
      {
        root: scrollContainer,
        threshold: 1.0,
      }
    );

    observer.observe(scrollPoint);

    return () => {
      observer.disconnect();
    };
  });

  return <div ref={scrollPointRef}></div>;
}
