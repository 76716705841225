import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  baseUrl,
  datadogInit,
  getAuth0RedirectUrl,
  runtimeEnv,
  WorknetAuthProviderWithRedirect,
} from '@worknet/app-core';

import ThemedChakraProvider from '~/shared/components/ThemedChakraProvider';

import Routes from './Routes';

datadogInit('copilot-site');

export default function App() {
  const client = new QueryClient();
  return (
    <StrictMode>
      <ThemedChakraProvider>
        <QueryClientProvider client={client}>
          <BrowserRouter basename={baseUrl}>
            <WorknetAuthProviderWithRedirect
              domain={runtimeEnv.auth0Domain}
              clientId={runtimeEnv.auth0ClientId}
              cacheLocation="localstorage"
              authorizationParams={{
                audience: runtimeEnv.auth0Audience,
                redirect_uri: getAuth0RedirectUrl(),
              }}
            >
              <Routes />
            </WorknetAuthProviderWithRedirect>
          </BrowserRouter>
        </QueryClientProvider>
      </ThemedChakraProvider>
    </StrictMode>
  );
}
