import { PropsWithChildren } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth0ProviderOptions, AppState, User } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';

import { baseUrl } from '../utils';

import { WorknetAuthProvider } from './WorknetAuthProvider';

export const WorknetAuthProviderWithRedirect = ({
  children,
  ...props
}: PropsWithChildren<Auth0ProviderOptions>) => {
  const location = useLocation();
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState, user?: User) => {
    if (user) {
      datadogLogs.setUser({
        id: user.id,
        name: user.name as string,
        email: user.email as string,
        orgName: user.org_name, // TODO: check if this is sent
      });
    }
    navigate(appState?.returnTo || location.pathname);
  };

  return (
    <WorknetAuthProvider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </WorknetAuthProvider>
  );
};

export function getAuth0RedirectUrl() {
  const base = window.location.origin;
  if (baseUrl === '/') {
    return base;
  }
  return `${base}?basePath=${encodeURI(baseUrl)}`;
}
