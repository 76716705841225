import { LogsEvent, StatusType } from '@datadog/browser-logs';

let prevError: LogsEvent['error'] | null = null;
const timestampMap = new Map<string, number>();
const HOUR = 3600000;

const checkErrorIsWarn: Array<(event: LogsEvent) => boolean> = [
  // Auth0 Popup opened timeout
  (event) => event.error?.error === 'timeout',

  // Auth0 silent access_token re-issue expired, user re-login is needed
  (event) => event.error?.error === 'login_required',

  // Auth0 popup is closed by the user
  (event) => event.message.includes('Error: Popup closed'),

  // ws-chat rejection when previous error was login_required, i.e. no valid token
  (event) => prevError?.error === 'login_required' && event.message.includes('xhr poll error'),
];

export const resilientErrorIfNeeded = (event: LogsEvent) => {
  if (event.error && event.status === StatusType.error) {
    prevError = event.error;
    const isWarn = checkErrorIsWarn.some((check) => check(event));
    if (isWarn) {
      const { message, error } = event;
      event.message = `${message} ${error.stack || error.causes || error.message || JSON.stringify(error)}`;
      delete event.error;
      event.status = StatusType.warn;
      return;
    }
  }
};

export const shouldSkipReport = (key: string) => {
  const isFirstTime = !timestampMap.has(key);

  if (isFirstTime) {
    timestampMap.set(key, Date.now());
  }
  const firstReportedTimestamp = timestampMap.get(key)!;

  return firstReportedTimestamp + HOUR < Date.now() || isFirstTime;
};

export const getEventKey = (event: LogsEvent): string => {
  return event.error?.message || event.message;
};
